import React, { useState, useContext, useEffect } from "react";
import ModalComponent from "../components/basic/ModalComponent";
import Lucrare from "../lucrare/Lucrare";
import RightComponentWrapper from "../components/basic/RightComponentWrapper";
import IstoricLucrare from "./IstoricLucrare";
import { UserContext } from "../context/UserContext";
import { showDateFormat, showTimeFormat, arraysMatch } from "../utils/Utils";
import { getLucrareService } from "../backend/LucrareService";
import { RefreshContext } from "../context/RefreshContext";
import { database } from "../firebase";
import { ref, get } from 'firebase/database';

const Detalii = ({ lucrareId, refreshLucrari }) => {
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const { isAdmin, isDispecer, user } = useContext(UserContext);
  const [currentLucrare, setCurrentLucrare] = useState();
  const { triggerCurrentLucrare } = useContext(RefreshContext);

  const getLucrare = async () => {
    try {
      const result = await getLucrareService(lucrareId);
      setCurrentLucrare(result);
    } catch (err) {
      //console.error(err);
    }
  };
  //pentru ca nu face refresh dupa salvare
  useEffect(() => {
    if (modal === false && lucrareId) getLucrare();
  }, [modal, lucrareId, triggerCurrentLucrare]);

  const closeModal = () => {
    setModal(false);
  };

  const rightClickDescriere = (e) => {
    e.preventDefault();

    if (currentLucrare.lucrareType === "revizii") return;

    setModal(true);

    if (
      currentLucrare.createdUser.toLowerCase() !==
      user?.userRoleDTO?.userName &&
      !isAdmin() &&
      !isDispecer()
    )
      setError(
        "O lucrare poate fi modificata doar de cel care a adaugat-o sau de un dispecer!"
      );
  };

  const getTextColorForUserParticipant = (participant) => {
    let classUser = "";
    currentLucrare.users.forEach((user) => {
      if (user.userName === participant) {
        classUser = "lucrare_item_user_participant_curent";
      }
    });

    return classUser;
  };

  const getTextColorForUserAtribuiti = (atribuit) => {
    let classUser = "";
    if (
      currentLucrare.confirmati &&
      currentLucrare.confirmati.includes(atribuit)
    ) {
      classUser = "lucrare_item_user_participant_curent";
    }

    return classUser;
  };

  const addNrTelefon = () => {
    if (isAdmin() || isDispecer()) {
      const itemsRef = ref(database, "logics");
            get(itemsRef).then(snapshot => {
        let items = snapshot.val();
        if (items === null) {
          if (currentLucrare.partNr) {
            itemsRef.push({
              userName: user?.userRoleDTO?.userName,
              nr: currentLucrare.partNr,
            });
          }
        } else {
          for (let item in items) {
            if (items[item].userName === user?.userRoleDTO?.userName) {
              return;
            } else {
              if (currentLucrare.partNr) {
                itemsRef.push({
                  userName: user?.userRoleDTO?.userName,
                  nr: currentLucrare.partNr,
                });
              }
            }
          }
        }
      });
    }
  };

  const isShowRegen = () => {
    return (
      currentLucrare.terSt ||
      currentLucrare.terHid ||
      currentLucrare.lucrareType === "revizii" ||
      currentLucrare.lucrareType === "iscir"
    );
  };

  return (
    <>
      {currentLucrare && (
        <div className="rightDashboardDetaliiWrapper">
          <ModalComponent
            size={error ? "sm" : "md"}
            show={modal}
            close={closeModal}
            headingText={
              currentLucrare.lucrareType === "comenzi"
                ? "Modificare Comanda"
                : currentLucrare.lucrareType === "oferte"
                  ? "Oferta"
                  : "Modificare Lucrare"
            }
            body={
              error ? (
                <p>{error}</p>
              ) : (
                <Lucrare
                  closeModal={closeModal}
                  lucrareId={currentLucrare.uid}
                  locationType={currentLucrare.lucrareType}
                  refreshLucrari={refreshLucrari}
                  setCurrentLucrare={setCurrentLucrare}
                />
              )
            }
          />

          <div onContextMenu={(e) => rightClickDescriere(e)}>
            <RightComponentWrapper
              label={
                currentLucrare.lucrareType === "comenzi"
                  ? "Furnizor"
                  : "Locatie"
              }
              value={
                currentLucrare.locatieDTO && currentLucrare.locatieDTO.locatie
              }
            />
            <div className="rightComponentWrapper">
              <p className={`rightComponentLabel d-inline-block mr-2`}>
                Descriere
              </p>
              <div className={"rightComponentValue d-inline-block"}>
                {currentLucrare.partNr ? (
                  <div className="lucrare_with_nr_tel">
                    <div className="d-inline">{currentLucrare.part1}</div>
                    <div onClick={addNrTelefon} className="d-inline nr_telefon">
                      {currentLucrare.partNr}
                    </div>
                    <div className="d-inline">{currentLucrare.part2}</div>
                  </div>
                ) : (
                  <div>{currentLucrare.descriere}</div>
                )}
              </div>
            </div>
          </div>
          <RightComponentWrapper
            label={"Adaugata de"}
            value={isShowRegen() ? "REGEN" : currentLucrare.createdUser}
          />
          <RightComponentWrapper
            label={"Adaugata la"}
            value={
              isShowRegen()
                ? `${showDateFormat(new Date(currentLucrare.createDate))}`
                : `${showDateFormat(
                  new Date(currentLucrare.createDate)
                )} ${showTimeFormat(new Date(currentLucrare.createDate))}`
            }
          />
          {currentLucrare && currentLucrare.atribuiti && (
            <RightComponentWrapper
              showRed={
                !arraysMatch(
                  currentLucrare.atribuiti,
                  currentLucrare.confirmati
                )
                  ? true
                  : false
              }
              label={"Atribuire"}
              value={currentLucrare.atribuiti.map((atribuit, index) => (
                <div
                  key={index}
                  className={`d-inline-block mr-1 ${getTextColorForUserAtribuiti(
                    atribuit
                  )}`}
                >{`${atribuit} `}</div>
              ))}
            />
          )}
          {currentLucrare && currentLucrare.dataTermen && (
            <RightComponentWrapper
              showRed={true}
              label={"Data/Ora"}
              value={`${showDateFormat(
                new Date(currentLucrare.dataTermen)
              )} ${showTimeFormat(new Date(currentLucrare.dataTermen))}`}
            />
          )}
          {currentLucrare && currentLucrare.startDate && (
            <RightComponentWrapper
              label={"Inceputa la"}
              value={`${showDateFormat(
                new Date(currentLucrare.startDate)
              )} ${showTimeFormat(new Date(currentLucrare.startDate))}`}
            />
          )}
          {currentLucrare && currentLucrare.realizatDate && (
            <RightComponentWrapper
              label={"Realizata la"}
              value={`${showDateFormat(
                new Date(currentLucrare.realizatDate)
              )} ${showTimeFormat(new Date(currentLucrare.realizatDate))}`}
            />
          )}
          {currentLucrare && currentLucrare.participanti && (
            <RightComponentWrapper
              label={"Participanti"}
              value={currentLucrare.participanti.map((participant, index) => (
                <div
                  key={index}
                  className={`d-inline-block mr-1 ${getTextColorForUserParticipant(
                    participant
                  )}`}
                >{`${participant} `}</div>
              ))}
            />
          )}
          {currentLucrare && currentLucrare.lucrareType === "administrativ" && currentLucrare.programata && currentLucrare.faza === 6 && (
            <RightComponentWrapper
              label={"Scadenta la"}
              value={
              <div style={{ color: "red" }}>
                {showDateFormat(new Date(currentLucrare.scadenta))}
              </div>
              }
            />
          )}
          {currentLucrare?.lucrareType !== "administrativ" &&
            currentLucrare?.lucrareType !== "notificari" &&
            currentLucrare?.lucrareType !== "comenzi" && (
              <RightComponentWrapper
                label={"Total ore"}
                value={currentLucrare.durata ? currentLucrare.durata : 0}
              />
            )}
          <div className="detalii_istoric">
            {currentLucrare && (
              <IstoricLucrare currentLucrare={currentLucrare} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Detalii;
